export const URLS = {
  Theme: "/api/organization/admin-theme",

  login: "/oauth/token",
  forgotPassword: "/api/users/forgot-password",
  resetForgottenPassword: "/api/users/password",
  resendVerificationCode: "/api/users/resend-code",
  verifyVerificationCode: "/api/users/verify-code",
  logout: "/authenticated/users/logout",

  getRoles: "/authenticated/roles",
  getRolePage: "/authenticated/roles/page",
  userRole: "/authenticated/roles",
  addRoles: "/authenticated/roles",
  getRolesById: (id) => `/authenticated/roles/${id}`,
  editRoles: (id) => `/authenticated/roles/${id}`,
  deleteRoles: (id) => `/authenticated/roles/${id}`,
  editPrivileges: (id) => `/authenticated/roles/${id}/privileges`,
  customerStatus: (id) => `/authenticated/accounts/${id}`,
  getPharmacyUsers: "/authenticated/pharmacy-users",
  getPharmacyUsersPage: "/authenticated/pharmacy-users/page",
  userPharmacyUsers: "/authenticated/pharmacy-users",
  addPharmacyUsers: "/authenticated/pharmacy-users",
  getPharmacyUsersById: (id) => `/authenticated/pharmacy-users/${id}`,
  editPharmacyUsers: (id) => `/authenticated/pharmacy-users/${id}`,
  deletePharmacyUsers: (id) => `/authenticated/pharmacy-users/${id}`,

  getOfferPeriod: "/authenticated/offer-period",
  getOfferPeriodPage: "/authenticated/offer-period/page",
  userOfferPeriod: "/authenticated/offer-period",
  addOfferPeriod: "/authenticated/offer-period",
  getOfferPeriodById: (id) => `/authenticated/offer-period/${id}`,
  editOfferPeriod: (id) => `/authenticated/offer-period/${id}`,
  deleteOfferPeriod: (id) => `/authenticated/offer-period/${id}`,

  getOrder: "/authenticated/orders",
  getOrderPage: "/authenticated/orders/page",
  userOrder: "/authenticated/orders",
  addOrder: "/authenticated/orders",
  getOrderById: (id) => `/authenticated/orders/${id}`,
  editOrder: (id) => `/authenticated/orders/${id}`,
  deleteOrder: (id) => `/authenticated/orders/${id}`,

  getEventConfigurationPage: "/authenticated/event-configuration/page",
  uploadExtEventConfiguration: '/authenticated/event-configuration/bulk-upload',
  extEventConfigurationListDownload: '/authenticated/event-configuration/export',

  getEventHistoryPage: "/authenticated/event-history/page",
  extEventHistoryListDownload: '/authenticated/event-history/export',

  getExtarnalWholesalerMappingPage: "/authenticated/external-wholesaler-mapping/page",

  getClientPosAccount: "/authenticated/client-pos-accounts",
  getClientPosAccountPage: "/authenticated/client-pos-accounts/page",
  userClientPosAccount: "/authenticated/client-pos-accounts",
  addClientPosAccount: "/authenticated/client-pos-accounts",
  getClientPosAccountById: (id) => `/authenticated/client-pos-accounts/${id}`,
  editClientPosAccount: (id) => `/authenticated/client-pos-accounts/${id}`,
  deleteClientPosAccount: (id) => `/authenticated/client-pos-accounts/${id}`,

  getSegmentation: "/authenticated/segmentation",
  getSegmentationPage: "/authenticated/segmentation/page",
  userSegmentation: "/authenticated/segmentation",
  addSegmentation: "/authenticated/segmentation",
  getSegmentationById: (id) => `/authenticated/segmentation/${id}`,
  editSegmentation: (id) => `/authenticated/segmentation/${id}`,
  deleteSegmentation: (id) => `/authenticated/segmentation/${id}`,

  getPredefinedOffer: "/authenticated/predefined-offers",
  getPredefinedOfferPage: "/authenticated/predefined-offers/page",
  userPredefinedOffer: "/authenticated/predefined-offers",
  addPredefinedOffer: "/authenticated/predefined-offers",
  getPredefinedOfferById: (id) => `/authenticated/predefined-offers/${id}`,
  editPredefinedOffer: (id) => `/authenticated/predefined-offers/${id}`,
  deletePredefinedOffer: (id) => `/authenticated/predefined-offers/${id}`,

  getManualOffer: "/authenticated/predefined-offers",
  getManualOfferPage: "/authenticated/manual-offers/page",
  userManualOffer: "/authenticated/predefined-offers",
  addManualOffer: "/authenticated/predefined-offers",
  getManualOfferById: (id) => `/authenticated/predefined-offers/${id}`,
  editManualOffer: (id) => `/authenticated/predefined-offers/${id}`,
  deleteManualOffer: (id) => `/authenticated/predefined-offers/${id}`,
  uploadManualOfferBulk: "/authenticated/manual-offers/bulk-upload",
  manualOffersListDownload: "/authenticated/manual-offers/export/csv",

  getBonusOffer: "/authenticated/predefined-offers",
  getBonusOfferPage: "/authenticated/predefined-offers/page",
  userBonusOffer: "/authenticated/predefined-offers",
  addBonusOffer: "/authenticated/predefined-offers",
  getBonusOfferById: (id) => `/authenticated/predefined-offers/${id}`,
  editBonusOffer: (id) => `/authenticated/predefined-offers/${id}`,
  deleteBonusOffer: (id) => `/authenticated/predefined-offers/${id}`,
  uploadBonusOfferBulk: "/authenticated/predefined-offers/bulk-upload",
  bonusOffersListDownload: "/authenticated/predefined-offers/export/csv",

  getStandardSegmentOffer: "/authenticated/standard-segment-offers",
  getStandardSegmentOfferPage: "/authenticated/standard-segment-offers/page",
  userStandardSegmentOffer: "/authenticated/standard-segment-offers",
  addStandardSegmentOffer: "/authenticated/standard-segment-offers",
  getStandardSegmentOfferById: (id) =>
    `/authenticated/standard-segment-offers/${id}`,
  editStandardSegmentOffer: (id) =>
    `/authenticated/standard-segment-offers/${id}`,
  deleteStandardSegmentOffer: (id) =>
    `/authenticated/standard-segment-offers/${id}`,

  getValidOffer: "/authenticated/valid-offers/page",
  getValidOfferPage: "/authenticated/valid-offers/page",
  userValidOffer: "/authenticated/valid-offers",
  addValidOffer: "/authenticated/valid-offers",
  getValidOfferById: (id) => `/authenticated/valid-offers/${id}`,
  editValidOffer: (id) => `/authenticated/valid-offers/${id}`,
  deleteValidOffer: (id) => `/authenticated/valid-offers/${id}`,

  getCommercialCondition: "/authenticated/commercial-conditions",
  getCommercialConditionPage: "/authenticated/commercial-conditions/page",
  userCommercialCondition: "/authenticated/commercial-conditions",
  addCommercialCondition: "/authenticated/commercial-conditions",
  getCommercialConditionById: (id) =>
    `/authenticated/commercial-conditions/${id}`,
  editCommercialCondition: (id) => `/authenticated/commercial-conditions/${id}`,
  deleteCommercialCondition: (id) =>
    `/authenticated/commercial-conditions/${id}`,

  getWholesalers: "/authenticated/wholesalers",
  getWholesalersPage: "/authenticated/wholesalers/page",
  userWholesalers: "/authenticated/wholesalers",
  addWholesalers: "/authenticated/wholesalers",
  getWholesalersById: (id) => `/authenticated/wholesalers/${id}`,
  editWholesalers: (id) => `/authenticated/wholesalers/${id}`,
  deleteWholesalers: (id) => `/authenticated/wholesalers/${id}`,

  getAccounts: "/authenticated/accounts",
  getAccountsPage: "/authenticated/accounts/page",
  userAccounts: "/authenticated/accounts",
  addAccounts: "/authenticated/accounts",
  getAccountsById: (id) => `/authenticated/accounts/${id}`,
  editAccounts: (id) => `/authenticated/accounts/${id}`,
  deleteAccounts: (id) => `/authenticated/accounts/${id}`,

  getCurrentUser: "authenticated/users/profile",
  getUsers: "/authenticated/users/page",
  getUserById: (id) => `/authenticated/users/${id}`,
  addUsers: `/authenticated/users`,
  editUsers: (id) => `/authenticated/users/${id}`,
  deleteUsers: (id) => `/authenticated/users/${id}`,
  toggleUsers: (id) => `/authenticated/users/${id}/toggle`,
  resetPassword: (id) => `/authenticated/users/${id}/reset-password`,
  updatedProfile: (id) => `/authenticated/users/${id}/profile`,
  changePassword: "/authenticated/users/change-password",

  language: "/authenticated/languages",
  languageList: "/api/languages",
  getLanguage: (id) => `/api/translations/${id}`,

  getLocations: "/authenticated/locations/page",
  addLocations: "/authenticated/locations",
  getLocationsById: (id) => `/authenticated/locations/${id}`,
  editLocationsById: (id) => `/authenticated/locations/${id}`,
  deleteLocationsById: (id) => `/authenticated/locations/${id}`,
  toggleVerifyLocations: (id) => `/authenticated/locations/${id}/toggle-verify`,

  uploadPharmacyUsersBulk: "/authenticated/pharmacy-users/bulk-upload",

  uploadOfferPeriodBulk: "/authenticated/offer-period/bulk-upload",

  uploadOrderBulk: "/authenticated/orders/bulk-upload",

  uploadClientPosAccountBulk: "/authenticated/client-pos-accounts/bulk-upload",
  uploadOrders: "/authenticated/orders/bulk-upload",

  uploadSegmentationBulk: "/authenticated/segmentation/bulk-upload",

  uploadPredefinedOfferBulk: "/authenticated/predefined-offers/bulk-upload",

  uploadStandardSegmentOfferBulk:
    "/authenticated/standard-segment-offers/bulk-upload",

  uploadValidOfferBulk: "/authenticated/valid-offers/bulk-upload",

  uploadCommercialConditionBulk:
    "/authenticated/commercial-conditions/bulk-upload",

  uploadWholesalerBulk: "/authenticated/wholesalers/bulk-upload",

  uploadAccountsBulk: "/authenticated/accounts/bulk-upload",

  wholesalerListDownload: "/authenticated/wholesalers/export/csv",
  pharmacyListDownload: "/authenticated/pharmacy-users/export/csv",

  uploadOfferPeriodBulkDownload: "/authenticated/offer-period/export/csv",
  accountListDownload: "/authenticated/accounts/export/csv",

  clientPosAccountListDownload: "/authenticated/client-pos-accounts/export/csv",
  orderListDownload: "/authenticated/orders/export/csv",

  segmentationListDownload: "/authenticated/segmentation/export/csv",

  predefinedOffersListDownload: "/authenticated/predefined-offers/export/csv",

  StandardSegmentOffersListDownload:
    "/authenticated/standard-segment-offers/export/csv",

  ValidOffersListDownload: "/authenticated/valid-offers/export/csv",

  commercialConditionsListDownload:
    "/authenticated/commercial-conditions/export/csv",

  OrderListDownload: "/authenticated/orders/export/csv",


  getFeedbackPage: "/authenticated/pharmacy-users/feedback/page",
  feedbackListDownload: "/authenticated/feedback/export/csv",


  uploadExtWholesalerMapping: '/authenticated/external-wholesaler-mapping/bulk-upload',
  extWholesalerMappingListDownload: '/authenticated/external-wholesaler-mapping/export/csv',


  

  getTempOrder: '/authenticated/temporary-orders/page',
  downloadTempOrderCSV: '/authenticated/temporary-orders/export/excel',

  locOrderPage: '/authenticated/loc/orders/page',
  downloadLocOrderCSV: '/authenticated/loc/orders/export/csv',

  getLinkTableData: '/authenticated/link/page',
  DownloadLinkTable: '/authenticated/locProductLinkTable/export/csv',
  UploadLinkTable: '/authenticated/LocProductLink/bulk-upload',
  draftTableData: '/authenticated/draftOrder/page',
  languageUpload: '/authenticated/languages/bulk-upload',
  languagesListing: '/authenticated/languages/page',
  saveTNC: '/authenticated/terms-and-conditions',
  exportLanguages: '/authenticated/languages/export/csv',
  uploadLabels: "/authenticated/localLanguageUploadExcel/bulk-upload",
  downloadLabels: "/authenticated/localLabelsUploadFormatExcel",
  getLableList: "/authenticated/localLanguages/page",
  getLangauges : "/authenticated/languages",
  getTNCData: "/api/terms-and-conditions?loc=all"
}